import React from "react";
import "./Features.css";

const featuresData = [
  {
    title: "Fully Insured",
    icon: "/images/insurance.png", // Ensure this path is correct
    alt: "insurance shield",
    description: "Rest assured our meet and greet service offers complete peace of mind with fully insured coverage."
  },
  {
    title: "24hr Security",
    icon: "/images/cctv-camera.png", // Ensure this path is correct
    alt: "security camera",
    description: "Our car park is safeguarded by round-the-clock security to ensure the utmost protection for your vehicle."
  },
  {
    title: "Valet Services",
    icon: "/images/valet.png", // Ensure this path is correct
    alt: "award icon",
    description: "We also offer jet washing and valet cleaning services, ensuring your vehicle is returned to you in pristine condition."
  },
  {
    title: "Electric Charging",
    icon: "/images/charging-station.png", // Ensure this path is correct
    alt: "electric charging",
    description: "While you travel, we’ll ensure your electric vehicle is fully charged and ready to go upon your return."
  }
  
  
];

const Feature = ({ title, icon, alt, description }) => (
  <div className="card">
    <div className="card-header">
      <img src={icon} alt={alt} />
      <h4>{title}</h4>
    </div>
    <p>{description}</p>
  </div>
);

function Features() {
  return (
    <div className="features-container">
      {featuresData.map((feature, index) => (
        <Feature
          key={index}
          title={feature.title}
          icon={feature.icon}
          alt={feature.alt}
          description={feature.description}
        />
      ))}
    </div>
  );
}

export default Features;

