import React from "react";

import './Directions.css'


function Directions() {
    return (
        <div className="component" id="directions">
            <div className="directions-flex">    
                <div className="content">
                    <h2 className="title">Directions to Orange Short Stay (Drop Off Point)</h2>
                    <ol>
                        <li><strong>Set Google Maps to London Stansted Airport</strong></li>
                        <li><strong>From the North</strong>:
                            <ul>
                                <li>Leave the M11 at J8.</li>
                            </ul>
                        </li>
                        <li><strong>From the South</strong>:
                            <ul>
                                <li>Leave the M11 at J8a.</li>
                            </ul>
                        </li>
                        <li><strong>Follow the Signs</strong>:
                            <ul>
                                <li>Follow the signs for London Stansted Airport to the Bassingbourn roundabout.</li>
                            </ul>
                        </li>
                        <li><strong>Bassingbourn Roundabout</strong>:
                            <ul>
                                <li>Take the 2nd exit onto Thremhall Avenue.</li>
                            </ul>
                        </li>
                        <li><strong>Thremhall Avenue Roundabout</strong>:
                            <ul>
                                <li>Take the 3rd exit onto Terminal Rd S.</li>
                            </ul>
                        </li>
                        <li><strong>Final Step</strong>:
                            <ul>
                                <li>Follow signs to Orange Premium Car Park.</li>
                            </ul>
                        </li>
                    </ol>
                    <p>With these clear steps, you'll reach the Orange Premium Car Park at London Stansted Airport without any hassle. Safe travels!</p>
                </div>
                <div className="mapBx">
                    <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9844.2042140791!2d0.2819976000000035!3d51.904436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d88f1a690e0cad%3A0xf76f6bea680e6e49!2sOrange%20Meet%20And%20Greet!5e0!3m2!1sen!2suk!4v1723149882839!5m2!1sen!2suk" 
                        allowFullScreen 
                        loading="lazy" 
                        referrerPolicy="no-referrer-when-downgrade" 
                        title="Map of Orange Meet And Greet Parking">
                    </iframe>
                    <br />
                    <button onClick={() => window.open('https://www.google.com/maps/place/London+Stansted+Airport+Premium+Short+Stay+Orange/@51.8888839,0.2567854,17z/data=!3m1!4b1!4m6!3m5!1s0x47d88f8974558331:0x115583c76480b1ab!8m2!3d51.8888839!4d0.2589741!16s%2Fg%2F11fhtjghhr', '_blank')}>
                        Open in Google Maps
                    </button>
                </div> 
            </div>
            <div className="points">
                    
         
            </div>       
        
                </div>

    );
}

export default Directions;
