import React from "react";
import { NavLink } from "react-router-dom";
import logo from '../omglogo.png'; // Import your logo image
import "./Header.css"; // For styling



const Header = () => {

    return (
        <header className="header">
          <div className="container">
            <div className="logo">
              <img src={logo} alt="Logo" />
            </div>
            <div className="spacer"></div>
            <nav className="nav-links">
    <div className="book-now">Book Now: +44 (0)7454 042 685 </div> {/* Add this line */}
    <div className="email">Email: enquiries@orangemeetandgreet.co.uk </div> {/* Add this line */}
    <div className="links">
      <NavLink to="/" className={({ isActive }) => (isActive ? "active-link" : "link")}>
        Home
      </NavLink>
      <NavLink to="/faqs" className={({ isActive }) => (isActive ? "active-link" : "link")}>
        FAQS
      </NavLink>
      <NavLink to="/reviews" className={({ isActive }) => (isActive ? "active-link" : "link")}>
        Reviews
      </NavLink>
      <NavLink to="/terms" className={({ isActive }) => (isActive ? "active-link" : "link")}>
        Terms
      </NavLink>
      {/* <NavLink to="/home" className={({ isActive }) => (isActive ? "active-link" : "link")}>
        Directions
      </NavLink> */}
    </div>
            </nav>
          </div>

        </header>
      );
};

export default Header;

