import React from "react";
import './Notice.css';


export default function Notice() {
    return (

        <div className="notice-container">
            <p>🎄 Still taking bookings for the holiday season!🎄</p>
            <p>  Call 44 (0)7454 042 685 </p>
        </div>



    )

}