import React, { useEffect, useState } from "react";
import Slider from "react-slick"; // Import Slider component
import "slick-carousel/slick/slick.css"; // Import slick styles
import "slick-carousel/slick/slick-theme.css"; // Import slick theme styles
import "./ReviewCarousel.css"; // Assuming you'll create a custom CSS file for styles

import "font-awesome/css/font-awesome.min.css"; // Import FontAwesome styles


const CustomArrow = ({ className, style, onClick, direction }) => (
  <div
    className={className}
    style={{
      ...style,
      background: "#444",
      borderRadius: "50%",
      width: "40px",
      height: "40px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#fff",
    }}
    onClick={onClick}
  >
    {direction === "next" ? ">" : "<"}
  </div>
);

const Review = ({ reviewer, starRating, comment, createTime }) => {
  const starCount = parseInt(starRating.split('_')[0]);

  return (
    <div className="review-card">
      <div className="review-header">
        <h4>{reviewer.displayName}</h4>
      </div>
      {comment && <p className="comment">{comment}</p>}
      <div className="review-footer">
        <div className="star-rating">
          {[...Array(5)].map((_, index) => (
            <i
              key={index}
              className={`fa fa-star ${index < starCount ? "filled" : "empty"}`}
            />
          ))}
        </div>
        <div className="review-date">{new Date(createTime).toLocaleDateString()}</div>
      </div>
    </div>
  );
};


function ReviewCarousel() {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    // Fetch the reviews.json file from the public folder
    fetch("/reviews.json")
      .then((response) => response.json())
      .then((data) => {
        setReviews(data.reviews || []); // Assuming reviews.json contains an array under the "reviews" key
      })
      .catch((error) => console.error("Error fetching reviews:", error));
  }, []);

  const settings = {
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 2,
    nextArrow: <CustomArrow direction="next" />,
    prevArrow: <CustomArrow direction="prev" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        {reviews.map((review, index) => (
          <Review
            key={index}
            reviewer={review.reviewer}
            starRating={review.starRating}
            comment={review.comment}
            createTime={review.createTime}
          />
        ))}
      </Slider>

    </div>
  
  );



}

export default ReviewCarousel;
