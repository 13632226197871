import React from "react";
import './About.css';
    


function About({ title, blurb1, blurb2, blurb3, contactInfo, imageSrc, imageAlt, reverseLayout }) {
    return (
        <div className="component">
            <div className={`aboutus-flex ${reverseLayout ? 'reverse' : ''}`}>
                <div className="content">
                    <h2 className="title">{title}</h2>

                    <p className="blurb" style={{ textAlign: 'left' }}>
                        {blurb1}
                    </p>

                    <p className="blurb">
                        {blurb2}
                    </p>

                    <p className="blurb">
                        {blurb3}
                    </p>

                    <p className="booknow">{contactInfo}</p>
                </div>

                <div className="imgBx">
                    <img src={imageSrc} alt={imageAlt} />
                </div>
            </div>
        </div>
    );
}

export default About;

