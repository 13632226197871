import React from "react";
import './Why.css';

function Why() {
    return (
        <div className="component">
            <div className="whyus-flex content">
                <h2 className="title">Why You Should Trust Us</h2>

                <p>
                Our team consists of fully qualified and insured drivers who 
                prioritize the careful handling of your vehicle. We ensure your car 
                is parked securely, record its mileage upon arrival, and operate under 
                24-hour security and surveillance for your peace of mind. We take pride in 
                achieving customer satisfaction, as reflected in the glowing reviews from our valued 
                clients. Your vehicle is treated with the utmost care and transparency, ensuring you can 
                trust us every step of the way.
                </p>

                <p style={{ textAlign: "left" }}>
                    From the moment you hand over your keys to the moment you return, we prioritize your peace of mind. 
                    With a proven track record of excellent service and countless satisfied customers, you can trust us 
                    to take the utmost care of your vehicle.
                </p>

                <p className="booknow" style={{ textAlign: "left" }}>
                    {/* Book Now Call 07454 042 685 */}
                </p>  
            </div>   
        </div>
    );
}


export default Why;
