import React from "react";
import './FAQs.css'; // Your custom CSS (if any)
import Accordion from 'react-bootstrap/Accordion';

export default function FAQS() {
    return (
        <div className="grid-container">
            <p></p>
            <p></p>
            <h2 id="subtitle">Frequently Asked Questions</h2>
            <div className="faq-component">
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Q. What methods of payment are accepted?</Accordion.Header>
                        <Accordion.Body>
                            A. We accept various payment methods for your convenience, including: bank transfer, mobile card machine or cash.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Q. How do I create a booking?</Accordion.Header>
                        <Accordion.Body>
                            A. Call 07454 042 685 directly for any queries and bookings. Online booking coming soon.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Q. What is your cancellation policy?</Accordion.Header>
                        <Accordion.Body>
                            A. Plans changed? Free cancellation up to 48 hours before your booking.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Q. Can I turn up on the day and book?</Accordion.Header>
                        <Accordion.Body>
                        A. Same day booking possible, please call directly at 07454 042 685 to find latest availability.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>Q. What are your hours of operation - and contact hours? </Accordion.Header>
                        <Accordion.Body>
                        A. Our Meet and Greet parking is open 24 hours a day to ensure 
                        you have convenient and reliable access for your flight at any time. 
                        Enjoy the peace of mind that comes with guaranteed parking availability 
                        around the clock. 
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header>Q. Are motorcycles allowed in Meet & Greet car parks?</Accordion.Header>
                        <Accordion.Body>
                        A. Meet and Greet parking is not available for motorcycles. 
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </div>
    );
}
