import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import Banner from './components/Banner';

import Header from './components/Header';


import Footer from './components/Footer';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Terms from './pages/Terms/Terms';
import Reviews from './pages/Reviews/Reviews';
import Home from './pages/Home/Home';
import FAQS from './pages/FAQS/FAQs';
import './App.css'


function App() {
  return (
    <div>
 
    <Router>
      <Header />
      <Banner></Banner>
      
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/terms" element={<Terms/>} />
        <Route path="/faqs" element={<FAQS/>} />
        <Route path="/reviews" element={<Reviews />} />
      </Routes>
    </Router>
     



      <Footer></Footer>

    </div>

       
         

  );
}

export default App;
