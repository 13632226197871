import React from 'react';
import Nav from 'react-bootstrap/Nav';
import './Footer.css';  // Import the CSS file

function Footer() {
  return (
    <div className="navbar-container">
      <Nav className="navbar justify-content-center" activeKey="/home">
        <Nav.Item>
          <Nav.Link href="/">Home</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="/faqs">FAQs</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="reviews">Reviews</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="terms">Terms</Nav.Link>
        </Nav.Item>
      </Nav>
      <p>© Orange Meet & Greet</p>
    </div>
  );
}

export default Footer;
