import React from "react";
import './Banner.css';


export default function Banner() {
    return (

        <div className="banner-container">
            <p></p>
        </div>



    )

}
