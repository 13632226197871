import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import Banner from "../../components/Banner";


const Reviews = () => {
  const [reviews, setReviews] = useState([]);

  // Fetch the JSON data
  useEffect(() => {
    fetch("/reviews.json")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch reviews");
        }
        return response.json();
      })
      .then((data) => setReviews(data.reviews))
      .catch((error) => console.error("Error fetching reviews:", error));
  }, []);

  const renderStars = (rating) => {
    const starCount = rating === "FIVE" ? 5 : parseInt(rating, 10); // Assuming full stars for FIVE
    return (
      <div style={{ color: "#FFD700" }}>
        {Array.from({ length: starCount }, (_, index) => (
          <FontAwesomeIcon key={index} icon={faStar} />
        ))}
      </div>
    );
  };

  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        padding: "20px",
        maxWidth: "800px", // Sets a max width
        margin: "0 auto", // Centers the container
      }}
    >
      <h2>Customer Reviews</h2>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        {reviews.map((review, index) => (
          <div
            key={index}
            style={{
              border: "1px solid #ddd",
              padding: "15px",
              borderRadius: "8px",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
              margin: "10px 0", // Adds space between cards
              width: "100%", // Makes cards responsive
            }}
          >
            <h4>{review.reviewer.displayName}</h4>
            {renderStars(review.starRating)}
            <p>{review.comment}</p>
            <small style={{ color: "#888" }}>
              {new Date(review.createTime).toLocaleDateString()}
            </small>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Reviews;
