import React from "react";
import About from './About';
import Why from './Why';
import Features from './Features';
import How from './How';
import Directions from './Directions';
import Banner from "../../components/Banner";
import './Home.css';
import Notice from "../../components/Notice";
import ReviewCarousel from "./ReviewCarousel";


export default function Home() {
    return (

        <div className="grid-container">
            
            <Notice></Notice>

            <About title="About Us"
                blurb1="Experience unparalleled convenience with our reliable meet and greet car parking service. Upon your arrival at the airport, our professional valet staff will be ready to greet you, assist with your luggage, and securely park your vehicle in a safe location. When you return, your car will be waiting for you, ensuring a swift and hassle-free departure."
                blurb2="Trust us to take care of your parking needs, so you can focus on your journey ahead."
                contactInfo="Book Now Call 44 (0)7454 042 685"
                imageSrc="/images/replacement-car-keys.png"
                imageAlt="car keys"
                reverseLayout={false}
            /> 
                

            <Why></Why>

            <Features></Features> 
          

            <p></p>

            <How></How>

            <ReviewCarousel></ReviewCarousel>

            <p className="point">Enjoy the convenience of our Meet & Greet service, 
            ensuring a smooth and stress-free start and end to your journey. </p>

            <Directions></Directions>
            
            <p className="point">Your Trusted Partner for Secure and Convenient Airport Parking - Book Now: 44 (0)7454 042 685</p> 
              

        </div>

)
}