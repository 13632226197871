import React from "react";
import './How.css';
import './About.css'


function How() {
    return (  
        <div className="component"> 
            <div className="how-flex">  
                <div className="imgBx">
                    <img src="./images/onphone.png" className="additional_images" alt="On Phone" /> {/* Added alt attribute */}
                </div>
                <div className="content">
                    <h2>How does Meet & Greet parking work?</h2> {/* Moved h2 outside of p tag */}
                    <p>Booking and parking with Meet & Greet is quick and easy. Follow these simple steps for a seamless experience:</p>
                    <ul>
                        <li><strong>Book Your Parking:</strong> Reserve your Meet & Greet service.</li>
                        <li><strong>Receive Instructions:</strong> The day before your travel, you'll receive a text message with full instructions.</li>
                        <li><strong>Arrival at the Airport:</strong>
                            <ul>
                                <li>Drive to the Orange Short Stay Car Park.</li>
                                <li>Your driver will be waiting to greet you.</li>
                            </ul>
                        </li>
                        <li><strong>Returning from Your Trip:</strong>
                            <ul>
                                <li>Once through passport control, head back to the same drop-off point.</li>
                                <li>Your car will be ready and waiting for you.</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="points">

          

      </div> 

        </div>
    );
}

export default How;
